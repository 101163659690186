import React from "react"
import Layout from "../components/layout/Layout.js"
import { AboveFold } from "../components/section/Section"
import { Row } from "../components/row/Row"
import { Column50 } from "../components/column/Column.js"
import { ImageContainer, ImageSmall } from "../components/image/Image"
import { TextMedium } from "../components/text/Text"
import { Title, Heading } from "../components/title/Title"
import { Link } from "../components/link/Link"
import img1 from "../static/homehero.png"
import Seo from "../components/seo"

function NotFoundPage () {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <AboveFold isCentered>
        <Row isCentered>
          <Column50>
            <ImageContainer>
              <ImageSmall src={img1} alt="" />
            </ImageContainer>
          </Column50>
          <Column50>
            <Title isCentered isPrimary title="404 - Page not found" />
            <Heading heading="We can't figure out what page you're looking for" />
            <TextMedium
              textMedium="Did you type the right URL in the address bar? If you did, it could be 
              that the page you're searching for is no longer available."
            />
            <TextMedium textMedium="But don't worry, we have plenty of awesome articles on our blog, as well as case studies and templates! 
            Just follow the links below." />
            <Link href="/blog/" isCentered anchortext="Read articles" />
            <Link href="/case-studies/" isCentered anchortext="Read case studies" />
            <Link href="/content-strategy-templates/" isCentered anchortext="Download templates" />
          </Column50>
        </Row>
      </AboveFold>
    </Layout>
  )
}

export default NotFoundPage
